import * as React from "react";
import { Badge, Card, CardBody, CardHeader } from "reactstrap";

interface PlayListEmbedProps {
  selectedGrade: string;
}

export const PlayListEmbed: React.FC<PlayListEmbedProps> = ({
  selectedGrade,
}) => {

  let playlistId = undefined;

  //2022
  // switch (selectedGrade) {
  //   case "K":
  //     playlistId = "0ATtJ8RwXDgZEVg2GquIyZ";
  //     break;
  //   case "1":
  //     playlistId = "2zhqgfE9Hf4LlpnGn8ovn9";
  //     break;
  //   case "2":
  //     playlistId = "5c6M1tvlxyAErh4pqU0lwp";
  //     break;
  //   case "3":
  //     playlistId = "1xL6IhfTuerXLjKIjDDSKC";
  //     break;
  //   case "4":
  //     playlistId = "55W1mp0UGBtBVtwkjUHCaC";
  //     break;
  //   case "5":
  //     playlistId = "4muQCCd1ZC03PQVWRuMrEy";
  //     break;
  //   case "6":
  //     playlistId = "66jczrS5QSfAhU6PLCNdkl";
  //     break;
  // }

  //2023
  switch (selectedGrade) {
    case "K":
      playlistId = "7IJLayi7DAPbhw1BFsazVt";
      break;
    case "1":
      playlistId = "1n2NYmtFC2YbDQgVZthTtj";
      break;
    case "2":
      playlistId = "2FEkVg7wVWt9CFLoUIrvvA";
      break;
    case "3":
      playlistId = "7Jw6SuEbksS0yCkqeKG1zi";
      break;
    case "4":
      playlistId = "7GmAoWPM4iagkTF3QmA5N6";
      break;
    case "5":
      playlistId = "1eAx6NZwmoJIlOqF0zhxy3";
      break;
    case "6":
      playlistId = "4WqaF01clpQe3WlpfvSbUq";
      break;
    case "5th/6th":
      playlistId = "7BnLw8QnSWGBgs7Q3t1wiV";
      // playlistId = "7BnLw8QnSWGBgs7Q3t1wiV";
      break;
  }

  return (

    <Card
      className="my-2"
      color="dark"
      outline
    >
      <CardBody className="p-1 p-1">

        <div className="musicselected-row">
          {playlistId &&

            <iframe style={{ borderRadius: "12px" }} src={"https://open.spotify.com/embed/playlist/" + playlistId + "?utm_source=generator"}
              width="100%"
              height="380"
              frameBorder="0"
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture">
            </iframe>

          }
        </div>

      </CardBody>
    </Card>
  );
};
