import React, { useCallback, useEffect, useState } from 'react';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
    DonorInfoSave,
    FirstNameChange,
    GradeChange,
    LastNameChange,
    SetOrderComplete,
    SetValidationError,
    UpdateVenmoOrderAsync,
    addFormFields,
    fetchRaceAsync,
    gradeDDToggle,
    initpaypalButton,
    removeFormFields,
    selectDonor,
    selectOrderCompleteMessage,
    selectPaypalButton,
    selectRaceStatus,
    selectSystemError,
    selectValidationError,
    selectchildrenArray,
    selectgradeDDArray,
    selectresults,
} from './raceSlice';
import { Alert, Button, Card, CardBody, CardFooter, CardImg, CardSubtitle, CardText, CardTitle, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { selectActiveTab, setActiveTab } from '../tabs/tabSlice';

import { Form, Field } from '@availity/form';
import * as yup from 'yup';
import classNames from 'classnames';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { UserProfile } from '../Interfaces';
import LoadingModal from '../LoadingModal';
import { setrefreshReCaptcha, setcaptchatoken, selectCaptchaToken, selectrefreshReCaptcha } from '../captcha/captchaSlice';
import { EffectsCard } from '../../components/EffectsCard';
import ClipboardCopy from '../../components/ClipboardCopy';
import { toggleRaceInfo } from '../Modals/modalSlice';
import RaceInfo from '../Modals/RaceInfo';
import { VenmoButton } from './venmoButton';
import { last } from 'lodash';
import Leaderboard from '../Modals/Leaderboard';

export function RaceDonationWizard(props: { channelBase: number; }) {
    const childrenArray = useAppSelector(selectchildrenArray);
    const gradeDDArray = useAppSelector(selectgradeDDArray);

    let paypalButton = useAppSelector(selectPaypalButton);
    const currentdonor = useAppSelector(selectDonor);
    const captchatoken = useAppSelector(selectCaptchaToken);
    const refreshReCaptcha = useAppSelector(selectrefreshReCaptcha);

    const ValidationError = useAppSelector(selectValidationError);

    const SystemError = useAppSelector(selectSystemError);

    const OrderCompleteMessage = useAppSelector(selectOrderCompleteMessage);

    const dispatch = useAppDispatch();

    const activeTab = useAppSelector(selectActiveTab);
    const raceStatus = useAppSelector(selectRaceStatus);

    const grades = ["Kindergarten", "1st", "2nd", "3rd", "4th", "5th", "6th"];

    const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/

    const raceDonationResults = useAppSelector(selectresults);

    const initFetch = useCallback(() => {
        dispatch(fetchRaceAsync((props.channelBase === 0 || props.channelBase === 200) ? "L" : "Sandbox"));
    }, [dispatch])

    useEffect(() => {
        initFetch()
    }, [initFetch])

    let childrenValidationScheme: any = {
    }
    let childrenInitialValues: any = {
    }

    for (let i = 0; i < childrenArray.length; i++) {
        childrenValidationScheme["FirstName" + i] = yup.string().required('Please enter a First Name');
        childrenValidationScheme["LastName" + i] = yup.string().required('Please enter a Last Name');

        childrenInitialValues["FirstName" + i] = childrenArray[i].firstname;
        childrenInitialValues["LastName" + i] = childrenArray[i].lastname;
    }

    let percent = 0;
    if (raceDonationResults && raceDonationResults.School_total > 0) {
        percent = Math.round(raceDonationResults.School_total / raceDonationResults.School_goal * 100);
    }

    const myStyle = {
        maxWidth: '1050px'
    }

    const cardStyle = {
        maxWidth: '750px'
    }

    const percentAsWidth = {
        width: percent + "%"
    }
    const childrenArrayCopy = childrenArray.map((x) => x);
    const DonationOptOutName = (childrenArrayCopy.length > 1) ? "Race For Education opt-out Family" : "Race For Education opt-out"
    const DonationAmount = ((childrenArrayCopy.length > 1) ? 75 : 50)
    const DonationName = "Race For Education Donation"

    return (
        <>

            {raceDonationResults &&
                <div id="countdown-wrap" className='rainbow'>

                    <div id="goal">{"Goal: $" + raceDonationResults.School_goal.toLocaleString("en-US")}</div>
                    <div id="glass">
                        <div id="progress" style={percentAsWidth}>
                        </div>
                    </div>
                    <div className="goal-stat">
                        <span className="goal-number">{percent + "%"}</span>
                        <span className="goal-label">Funded</span>
                    </div>
                    <div className="goal-stat">
                        <span className="goal-number">{"$" + raceDonationResults.School_total}</span>
                        <span className="goal-label">Raised</span>
                    </div>
                    <div className="goal-stat">

                        <Leaderboard></Leaderboard>
                    </div>
                    {/* <div className="goal-stat">
                    <span className="goal-number">38</span>
                    <span className="goal-label">Sponsors</span>
                </div> */}
                </div>
            }

            <Container style={myStyle} className='mt-5' >

                <h4>RACE FOR EDUCATION 2024-2025</h4>
                <h5>
                    {/* <p>
                        We are going to keep racing throughout October. Fun was had by all at our race for education! Please continue to share our link https://lje-pto.com/race or donations can continue to be sent into school labeled with name and Race for Education.
                        We have decided to continue donations throughout October. While Kindergarten has already locked in their pizza party for 1st place, we will provide an additional pizza party for whichever grade comes in 2nd place.
                        Parents, Family and Friends who have donated and continue to donate up until Oct 31st will be automatically be entered to win a $100 Tomisino's giftcard. Winner for the 2nd Pizza Party and Giftcard will be announced on Friday November 1st.
                    </p> */}
                    {/* <span>We won't let rain bring us down!  Kindergarten locked in a pizza party!</span> */}
                    {/* <span
                        className="raceInfo1"><img
                            alt="" src="https://www.shutterstock.com/image-vector/isolated-happy-slice-pizza-emote-260nw-1127962724.jpg"
                            className="raceInfo2"
                            title="" /></span>
                    One more grade can win. We will be giving away another pizza party to the next top earning class 1st-6th.
                    All donations collected this year up to our rain date 10/20 will be counted. Keep them coming in!</span> */}
                    <span className="c18">Race for education was a success!  Donations will continue to be accepted. Keep them coming in!  Funds
                    raised from this event will help to cover the costs of all LJE-PTO sponsored activities, including field
                    trips, assemblies, dances, RIF events, the summer reading program, and other activities that support student
                    learning.</span>
                </h5>
                
                <h5>Help raise funds for our most important fundraiser of the year!
                    <Button className='btn btn-info-outline' color="info"
                        onClick={() => {
                            dispatch(toggleRaceInfo());
                        }}>
                        <i className={"main fa fa-circle-info"} style={{ color: "skyblue" }}></i>
                    </Button>
                    <RaceInfo></RaceInfo>
                </h5>

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classNames({ active: activeTab === 1 })}
                            onClick={() => { dispatch(setActiveTab(1)) }} to={''}                  >
                            Step 1
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classNames({ active: activeTab === 2 })}
                            onClick={() => {
                                // dispatch(setActiveTab(2))
                            }} to={''}                  >
                            Step 2
                        </NavLink>
                    </NavItem>
                    {/* <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classNames({ active: activeTab === 3 })}
                            onClick={() => { dispatch(setActiveTab(3)) }} to={''}                  >
                            Step 3
                        </NavLink>
                    </NavItem> */}

                </Nav>
                <TabContent activeTab={activeTab}>
                    {/* <TabPane tabId={1}>
                        Lets get started with Parent/Guardian information!
                        <div className="form-row">
                            <Form
                                initialValues={{
                                    Name: '',
                                    phone: '',
                                    email: '',

                                }}
                                validationSchema={
                                    yup.object().shape({
                                        Name: yup.string().required('Please enter a Name'),
                                        phone: yup.string().required('Please enter a Phone').matches(phoneRegExp, 'Phone number is not valid'),
                                        email: yup.string().email("Invalid email format").required('Please enter a valid email'),
                                    })}
                                onSubmit={(values) => {

                                    let vals: UserProfile = JSON.parse(JSON.stringify(values));
                                    vals.captchatoken = captchatoken;

                                    dispatch(DonorInfoSave(vals));

                                    dispatch(setActiveTab(2));
                                    // dispatch(setrefreshReCaptcha(!refreshReCaptcha));
                                }}
                            >
                                {(SystemError !== '' || Error !== '') && <Alert color="danger">There was a system error.  Please try again or if the issue persists please send email to <a href="mailto:lehmanjacksonptoboard@gmail.com">lehmanjacksonptoboard@gmail.com</a>. </Alert>}
                               
                                <div className="col-md-6 mb-3">
                                    <Field
                                        name="Name"
                                        label="Contact Name"
                                        type="text"
                                        maxLength={50}
                                        style={{ maxWidth: "370px" }}
                                    />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <Field
                                        name="phone"
                                        label="Contact Cell Phone"
                                        type="text"
                                        maxLength={15}
                                        style={{ maxWidth: "370px" }}
                                    />

                                </div>

                                <div className="col-md-6 mb-3">
                                    <Field
                                        name="email"
                                        label="Contact Email"
                                        type="text"
                                        maxLength={100}
                                        style={{ maxWidth: "370px" }}
                                    />
                                </div>
                                <div className="d-flex flex-row justify-content-between">

                                    <div className="d-flex flex-col">
                                        <button className="btn btn-inverse btn-primary btn-sm" id="submit">
                                            <strong>Next</strong>
                                        </button>

                                    </div>

                                </div>

                            </Form>
                        </div>
                    </TabPane> */}
                    <TabPane tabId={1}>
                        <div className="form-row">
                            <Form
                                initialValues={childrenInitialValues}
                                validationSchema={
                                    yup.object().shape(childrenValidationScheme)
                                }
                                onSubmit={(values) => {

                                    // let vals: UserProfile = JSON.parse(JSON.stringify(values));
                                    // vals.captchatoken = captchatoken;

                                    let isValid = true;

                                    for (let index = 0; index < childrenArray.length; index++) {
                                        if (childrenArray[index].gradesection === undefined || childrenArray[index].gradesection === "") {
                                            isValid = false;
                                            dispatch(SetValidationError("Please select a grade for each student"));
                                        }
                                        if (childrenArray[index].firstname === undefined || childrenArray[index].firstname === "") {
                                            isValid = false;
                                            dispatch(SetValidationError("Please select a first and last name for each student"));
                                        }
                                        if (childrenArray[index].lastname === undefined || childrenArray[index].lastname === "") {
                                            isValid = false;
                                            dispatch(SetValidationError("Please select a first and last name for each student"));
                                        }
                                    }

                                    if (isValid) {
                                        dispatch(initpaypalButton({
                                            buttonNum: Math.floor(Math.random() * 101), channel: (props.channelBase === 0 || props.channelBase === 200) ? 0 : 100,
                                            consumerOnComplete: function (params: any) {
                                                // Your onComplete handler
                                                console.table(params);
                                                dispatch(SetOrderComplete("Thank you for your donation!  Your order number is " + params.tx + " please keep this number for your records."));
                                            }
                                        }))
                                        dispatch(initpaypalButton({
                                            buttonNum: Math.floor(Math.random() * 101), channel: (props.channelBase === 0 || props.channelBase === 200) ? 1 : 101,
                                            consumerOnComplete: function (params: any) {
                                                // Your onComplete handler
                                                console.table(params);
                                                dispatch(SetOrderComplete("Thank you for your donation!  Your order number is " + params.tx + " please keep this number for your records."));
                                            }
                                        }))
                                        dispatch(setActiveTab(2));
                                    }
                                    // dispatch(setrefreshReCaptcha(!refreshReCaptcha));
                                }}
                            >
                                {(SystemError !== '') && <Alert color="danger">There was a system error.  Please try again or if the issue persists please send email to <a href="mailto:lehmanjacksonptoboard@gmail.com">lehmanjacksonptoboard@gmail.com</a>. </Alert>}


                                <Label for="childList">Student or students attending LJE</Label>
                                {(ValidationError !== '') && <div className='text-danger'>{ValidationError}</div>}

                                {childrenArray.map(
                                    (element, index) => {

                                        let propsordefault = element.gradesection
                                            ? element.gradesection
                                            : "Select Grade";
                                        return (
                                            <Card key={"Child" + index} className='mb-2' style={cardStyle}>
                                                <div className="col-md-6 mb-3">
                                                    <Field
                                                        name={"FirstName" + index}
                                                        label="First Name"
                                                        type="text"
                                                        maxLength={50}
                                                        style={{ maxWidth: "370px" }}
                                                        key={"Childfirstname" + index}
                                                        placeholder="First Name"
                                                        value={element.firstname || ""}
                                                        required
                                                        onChange={(e) =>
                                                            dispatch(FirstNameChange({ i: index, firstname: e.target.value }))
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">

                                                    <Field
                                                        name={"LastName" + index}
                                                        label="Last Name"
                                                        type="text"
                                                        maxLength={50}
                                                        style={{ maxWidth: "370px" }}
                                                        key={"Childlastname" + index}
                                                        placeholder="Last Name"
                                                        value={element.lastname || ""}
                                                        required
                                                        onChange={(e) =>
                                                            dispatch(LastNameChange({ i: index, lastname: e.target.value }))
                                                        }
                                                    />
                                                </div>
                                                <div className="d-flex flex-row justify-content-between">
                                                    <div className="col-md-6 mb-3">

                                                        <Dropdown key={"GradeDD" + index} isOpen={gradeDDArray[index]} toggle={() =>
                                                            dispatch(gradeDDToggle({ i: index }))
                                                        }>
                                                            <DropdownToggle caret>
                                                                {propsordefault}
                                                            </DropdownToggle>
                                                            <DropdownMenu key={"GradeDDM" + index}>
                                                                {grades.map((grade) => (
                                                                    <div className="row" key={grade}>
                                                                        <DropdownItem
                                                                            value={grade}
                                                                            onClick={() => {
                                                                                dispatch(GradeChange({ i: index, gradesection: grade }))
                                                                            }}

                                                                        >
                                                                            {grade}
                                                                        </DropdownItem>
                                                                        {/* <div className="col-sm-4">
                                                                        <DropdownItem onClick={() => {
                                                                            dispatch(GradeChange({ i: index, gradesection: grade + "-2" }))
                                                                        }}>
                                                                            {grade + "-2"}
                                                                        </DropdownItem>
                                                                    </div>
                                                                    <div className="col-sm-4">
                                                                        <DropdownItem onClick={() => {
                                                                            dispatch(GradeChange({ i: index, gradesection: grade + "-3" }))
                                                                        }}>
                                                                            {grade + "-3"}
                                                                        </DropdownItem>
                                                                    </div> */}
                                                                    </div>
                                                                ))}
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </div>
                                                    {index > 0 ||
                                                        (index <= 0 &&
                                                            childrenArray.length >
                                                            1) ? (
                                                        <div className="col-md-6 mb-3">
                                                            <button key={"Graderm" + index}
                                                                type="button"
                                                                className="button remove"
                                                                onClick={() =>
                                                                    dispatch(removeFormFields({ i: index }))
                                                                }
                                                            >
                                                                Remove Student
                                                            </button>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Card>
                                        );
                                    }
                                )}
                                <div className="form-row">
                                    <div className="col-md-6 mb-3">
                                        <Button onClick={() => dispatch(addFormFields())}>
                                            <span className="fa fa-plus"></span> Add Student
                                        </Button>
                                    </div>
                                </div>
                                <div className="d-flex flex-row justify-content-between">

                                    <div className="d-flex flex-col">
                                        <button className="btn btn-inverse btn-primary btn-sm" id="submitChildren" type='submit'>
                                            <strong>Next</strong>
                                        </button>

                                    </div>

                                </div>


                                {/* <LoadingModal isLoading={Status === "loading"}></LoadingModal> */}
                            </Form>
                        </div>
                    </TabPane>
                    <TabPane tabId={2}>
                        <h3>Complete one or more of the following options to qualify your student(s). Students will be awarded a bracelet for participation! Top earning grade will receive a pizza party!</h3>
                        <div className="containereffects row d-flex flex-row">
                            <div className="col-md-4 col-sm-2 col-xs-12">
                                <Card
                                    className="my-2"
                                    color="light">
                                    <CardBody>
                                        <div className="content row d-flex flex-row justify-content-around align-items-center">
                                            <h3>{"Donate $" + ((childrenArray.length > 1) ? "75" : "50") + " for " + ((childrenArray.length > 1) ? "family " : "") + "opt-out."}</h3>
                                            {OrderCompleteMessage === "" &&
                                                <div id={(props.channelBase === 0 || props.channelBase === 200) ? "ppparent" + 0 : "ppparent" + 100} className="p-2 bd-highlight">

                                                    <div id={(props.channelBase === 0 || props.channelBase === 200) ? "paypal-donate-button-container" + 0 : "paypal-donate-button-container" + 100}></div>
                                                </div>
                                            }

                                            {/* {childrenArrayCopy.length > 1 && OrderCompleteMessage === "" && (props.channelBase === 100 || props.channelBase === 200) &&
                                                <>
                                                    <hr />
                                                    <VenmoButton channelBase={props.channelBase} currentdonor={currentdonor} captchatoken={captchatoken}
                                                        amount={DonationAmount}
                                                        DonationName={DonationOptOutName}
                                                        onApprove={(data: any) => {

                                                            return new Promise((resolveInner) => {
                                                                dispatch(UpdateVenmoOrderAsync({ DonationName: "Race for Education opt-out Family", amount: 75, sol: (props.channelBase === 0 || props.channelBase === 200) ? "l" : "Sandbox", orderid: data.orderID }));
                                                            });
                                                        }}
                                                        createOrder={() => {

                                                            let dataIn: UserProfile = JSON.parse(JSON.stringify({ captchatoken: captchatoken }));
                                                            let postData = { DonationName: "Race for Education opt-out Family", amount: 75, sol: (props.channelBase === 0 || props.channelBase === 200) ? "l" : "Sandbox", CustomData: { Name: currentdonor.Name, email: currentdonor.email, cell: currentdonor.phone, childrenArray: currentdonor.childrenArray, Clearances: currentdonor.clearances }, userProfile: dataIn }

                                                            return fetch(
                                                                process.env.REACT_APP_API_HOST +
                                                                `/Transactions/CreateVenmoOrder`,
                                                                {
                                                                    method: "POST",
                                                                    // credentials: "include",
                                                                    body: JSON.stringify(postData),
                                                                    headers: {
                                                                        pragma: "no-cache",
                                                                        "cache-control": "no-cache",
                                                                        "content-type": "application/json",
                                                                    },
                                                                }
                                                            )
                                                                .then((response: Response) => {
                                                                    if (!response.ok) {
                                                                        if (response.statusText && response.statusText !== '') {
                                                                            throw response.statusText;
                                                                        }
                                                                        else {
                                                                            throw "System Error";
                                                                        }
                                                                    } else {
                                                                        return response.json() as Promise<string>;
                                                                    }
                                                                })
                                                                .catch((error) => {
                                                                    console.table(error);
                                                                    throw error;
                                                                });

                                                        }}
                                                    ></VenmoButton>
                                                </>

                                            }

                                            {childrenArrayCopy.length === 1 && OrderCompleteMessage === "" && (props.channelBase === 100 || props.channelBase === 200) &&
                                                <>
                                                    <hr />
                                                    <VenmoButton channelBase={props.channelBase} currentdonor={currentdonor} captchatoken={captchatoken}
                                                        amount={DonationAmount}
                                                        DonationName={DonationOptOutName}
                                                        onApprove={(data: any) => {

                                                            return new Promise((resolveInner) => {
                                                                dispatch(UpdateVenmoOrderAsync({ DonationName: "Race for Education opt-out", amount: 50, sol: (props.channelBase === 0 || props.channelBase === 200) ? "l" : "Sandbox", orderid: data.orderID }));
                                                            });
                                                        }}
                                                        createOrder={() => {

                                                            let dataIn: UserProfile = JSON.parse(JSON.stringify({ captchatoken: captchatoken }));
                                                            let postData = { DonationName: "Race for Education opt-out", amount: 50, sol: (props.channelBase === 0 || props.channelBase === 200) ? "l" : "Sandbox", CustomData: { Name: currentdonor.Name, email: currentdonor.email, cell: currentdonor.phone, childrenArray: currentdonor.childrenArray, Clearances: currentdonor.clearances }, userProfile: dataIn }

                                                            return fetch(
                                                                process.env.REACT_APP_API_HOST +
                                                                `/Transactions/CreateVenmoOrder`,
                                                                {
                                                                    method: "POST",
                                                                    // credentials: "include",
                                                                    body: JSON.stringify(postData),
                                                                    headers: {
                                                                        pragma: "no-cache",
                                                                        "cache-control": "no-cache",
                                                                        "content-type": "application/json",
                                                                    },
                                                                }
                                                            )
                                                                .then((response: Response) => {
                                                                    if (!response.ok) {
                                                                        if (response.statusText && response.statusText !== '') {
                                                                            throw response.statusText;
                                                                        }
                                                                        else {
                                                                            throw "System Error";
                                                                        }
                                                                    } else {
                                                                        return response.json() as Promise<string>;
                                                                    }
                                                                })
                                                                .catch((error) => {
                                                                    console.table(error);
                                                                    throw error;
                                                                });

                                                        }}
                                                    ></VenmoButton>
                                                </>

                                            } */}
                                            {OrderCompleteMessage !== "" && <Alert>
                                                {OrderCompleteMessage}
                                            </Alert>

                                            }
                                        </div>
                                    </CardBody>
                                    <CardFooter>

                                        <div className='my-2 fs-6'>*if you wish to send in a check, send payment with your contact info and students info to school clearly label it as "Race for education" in a sealed envelope. Checks can be made payable to "LJE-PTO".</div>
                                    </CardFooter>
                                </Card>
                                {/* <EffectsCard
                                    Title={"Donate $" + ((childrenArray.length > 1) ? "75" : "50") + " for " + ((childrenArray.length > 1) ? "family " : "") + "opt-out."}
                                    Icon="fa-hand-holding-heart"
                                    Color="pink"
                                    Animation="rocket-flight"
                                // Link="/Do/App1"
                                >
                                    <div className="d-flex justify-content-around">
                                        <div className="d-flex justify-content-around bd-highlight flex-wrap">
                                            
                                        </div>
                                    </div>
                                </EffectsCard> */}
                            </div>
                            <div className="col-md-4 col-sm-2 col-xs-12">
                                <Card
                                    className="my-2"
                                    color="light">
                                    <CardBody>
                                        <div className="content row d-flex flex-row justify-content-around align-items-center">
                                            <h3>Sponsor one or more students with a one time donation!</h3>
                                            {OrderCompleteMessage === "" &&
                                                <div id={(props.channelBase === 0 || props.channelBase === 200) ? "ppparent" + 1 : "ppparent" + 101} className="p-2 bd-highlight">
                                                    <div id={(props.channelBase === 0 || props.channelBase === 200) ? "paypal-donate-button-container" + 1 : "paypal-donate-button-container" + 101}></div>
                                                </div>
                                            }

                                            {/* {OrderCompleteMessage === "" && (props.channelBase === 100 || props.channelBase === 200) &&
                                                <>
                                                    <hr />
                                                    <VenmoButton channelBase={props.channelBase} currentdonor={currentdonor} captchatoken={captchatoken}
                                                        amount={DonationCustomAmount}
                                                        DonationName={DonationName}

                                                    ></VenmoButton>
                                                </>

                                            } */}

                                            {OrderCompleteMessage !== "" && <Alert>
                                                {OrderCompleteMessage}
                                            </Alert>

                                            }
                                        </div>
                                    </CardBody>
                                    <CardFooter>
                                        <div className='my-2 fs-6'>*if you wish to mail a check via usps, send payment to lehman-jackson mailing address with your contact info and students info and clearly label it as "Race for education". Checks can be made payable to "LJE-PTO".</div>
                                        <div>LEHMAN-JACKSON ELEMENTARY</div>
                                        <p className="">Market St.<br />P.O.Box 38<br />Lehman, PA 18627<br />(570) 675-2165</p>


                                    </CardFooter>
                                </Card>
                                {/* <EffectsCard
                                    Title={"Sponsor one or more students with a one time donation!"}
                                    Icon="fa-hand-holding-heart"
                                    Color="pink"
                                    Animation="rocket-flight"
                                // Link="/Do/App1"
                                >
                                    <div className="d-flex justify-content-around">
                                        <div className="d-flex justify-content-around bd-highlight flex-wrap">
                                            <div id={(props.channelBase === 0 || props.channelBase === 200) ? "ppparent" + 1 : "ppparent" + 101} className="p-2 bd-highlight">
                                                <div id={(props.channelBase === 0 || props.channelBase === 200) ? "paypal-donate-button-container" + 1 : "paypal-donate-button-container" + 101}></div>
                                                </div>
                                        </div>
                                    </div>
                                </EffectsCard> */}
                            </div>
                            <div className="col-md-4 col-sm-2 col-xs-12">
                                <Card
                                    className="my-2"
                                    color="light">
                                    <CardBody>
                                        <div>Share with 10+ of your family or friends!  Each grade will be rewarded based on donations received.</div>
                                        <ClipboardCopy />

                                        <img alt="AdvantEdge Card" src="https://cdn.sanity.io/images/vp13tjdy/production/de134758280764530c487c997a294360fb21eda5-450x450.png" className="img-fluid" />
                                    </CardBody>

                                </Card>
                            </div>
                            {/* <div className="col-md-4 col-sm-2 col-xs-12">
                            <EffectsCard
                                Title="Request Mailing Labels"
                                Icon="fa-envelope"
                                Color="pink"
                                Animation="rocket-flight"
                            // Link="/Do/App1"
                            >
                                <Button
                                    style={{ cursor: "pointer" }}
                                    className="">
                                    Request Mailing Labels by 9/11/2023, complete and return them to school by 9/21/2023!  Students will be awarded bracelet for full participation!
                                    <i className="fa fa-envelope" aria-hidden="true"></i>
                                </Button>
                            </EffectsCard>
                        </div> */}
                        </div>
                    </TabPane>
                </TabContent>
                <div className="d-flex flex-col">
                    <div className="d-flex flex-col">
                        <GoogleReCaptcha
                            refreshReCaptcha={refreshReCaptcha}
                            onVerify={useCallback((token) => {
                                dispatch(setcaptchatoken(token))
                            }, [refreshReCaptcha])}
                        />
                        {/* <div id="recaptchaform"></div> */}
                    </div>
                </div>
            </Container>
        </>
    );
}
