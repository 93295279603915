import * as React from "react";

import {
  Alert,
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardLink,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import sanityClient from "../../client.js";
import { Entry, EventSong, Post, Song, Student, StudentResponse } from "./Interfaces";

// import BlockContent from "@sanity/block-content-to-react";
import { GoogleSignIn } from "../user/GoogleSignIn";
import { CommitteeButton } from "../../components/CommitteeButton";
import { GradeSelector } from "../../components/GradeSelector";
// import SpotifyResults from "../components/modals/SpotifyResults";
import { SearchResults } from "../../components/SearchResults";
import {
  faExternalLinkAlt,
  faCarCrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlayListEmbed } from "../../components/PlayListEmbed";
const ExternalLinkAlt = <FontAwesomeIcon icon={faExternalLinkAlt} />;
const networkError = (
  <FontAwesomeIcon icon={faCarCrash} color="red" size="4x" />
);

const systemError = "We could not authorize you. This page is for Lehman-Jackson students or specific authorized users.";
const submitSystemError = "There was a problem.  please try again or contact us via our help form.";

const emptyUser = { name: "", email: "", currentSongs: [], Status: "", credential: "", thumbnail: "", grade: "", };

const emptySong = { song: "", artist: "", selection: "", thumbnail: "", preview_url: "", external_urls: { spotify: "", }, spotifyid: undefined };


const apiurl = process.env.REACT_APP_API_HOST;
export default class MusicSurvey extends React.PureComponent<
  {},
  {
    isOpen: boolean;
    slug: string;
    postData: EventSong[];
    step: string;
    isLoading: boolean;
    errormessage: string | undefined;
    submiterrormessage: string | undefined;
    searcherrormessage: string | undefined;
    successmessage: string | undefined;
    selectionStarted: boolean;
    currentUser: Student;
    availableEntries: Entry[];
    submitStarted: boolean;
    searchStarted: boolean;
    writeInStarted: boolean;
    submitSearchStarted: boolean;
    currentSong: Song;
    searchSongs: Song[];
    errors: string[];
    isLoggedIn: boolean;
    credential: string;
    selectedGrade: string;
    searchTerm: string;
    writeinTerm: string;
  }
> {
  public state = {
    isOpen: false,
    slug: "",
    postData: [],
    step: "Login",
    isLoading: false,
    errormessage: undefined,
    submiterrormessage: undefined,
    searcherrormessage: undefined,
    successmessage: undefined,
    selectionStarted: false,
    submitStarted: false,
    searchStarted: false,
    writeInStarted: false,
    submitSearchStarted: false,
    currentUser: emptyUser,
    currentSong: emptySong,
    errors: [],
    isLoggedIn: false,
    credential: "",
    selectedGrade: "5th/6th",
    availableEntries: [],
    searchTerm: "",
    writeinTerm: "",
    searchSongs: [],
  };


  public CancelSelection(): void {
    this.setState({
      isOpen: false,
      slug: "",
      isLoading: false,
      errormessage: undefined,
      selectionStarted: false,
      submitStarted: false,
    });

  }


  public Authorize = (res: any) => {

    if (res.credential) {

      fetch(apiurl + `/Music/AuthorizeStudent?credential=` + res.credential, {
        method: "GET",
        credentials: "include",
        headers: {
          pragma: "no-cache",
          "cache-control": "no-cache",
          "content-type": "application/json",
        },
      })
        .then((response) => response.json() as Promise<StudentResponse>)
        .then((data) => {
          //TODO: SHOW SUCCESS
          if (data.errors) {
            this.setState({
              step: "ErrorLogin",
              currentUser: data.student,
              availableEntries: data.availableEntries,
              selectedGrade: data.student.grade,
              isLoading: false,
              errors: data.errors

            });
          } else {
            if (data.student.Status === "Existing") {
              this.setState({
                step: "Existing",
                currentUser: data.student,
                availableEntries: data.availableEntries,
                selectedGrade: data.student.grade,
                isLoading: false,
                isLoggedIn: true,

              });
            } else {
              if (data.student.Status === "New") {
                this.setState({
                  step: "New",
                  currentUser: data.student,
                  availableEntries: data.availableEntries,
                  selectedGrade: data.student.grade,
                  isLoading: false,
                  errormessage: "",
                  isLoggedIn: true,
                });
              } else {
                this.setState({
                  step: "ErrorLogin",
                  isLoading: false,
                  currentUser: emptyUser,
                  errormessage: systemError,
                });
              }
            }
          }
        })
        .catch((ex) => {
          //TODO: log client errors
          //TODO: SHOW ERROR

          this.setState({
            step: "ErrorLogin",
            isLoading: false,
            currentUser: emptyUser,
            errormessage: systemError,
          });
        });

      this.setState({
        isLoading: true,
        currentUser: emptyUser,
      });
    } else {
      this.setState({
        step: "ErrorLogin",
        isLoading: false,
        currentUser: emptyUser,
        errormessage: systemError,
      });
    }
  };

  public handleSearch = () => {

    if (this.state.currentUser.email !== "") {
      //console.log(JSON.stringify(this.state.currentUser));

      fetch(apiurl + `/Music/Search?searchTerm=` + this.state.searchTerm + `&credential=` + this.state.currentUser.credential, {
        method: "GET",
        credentials: "include",
        headers: {
          pragma: "no-cache",
          "cache-control": "no-cache",
          "content-type": "application/json",
        },
      })
        .then((response) => response.json() as Promise<Song[]>)
        .then((data) => {

          this.setState({
            searchSongs: data,
            isLoading: false,
            submitSearchStarted: false,

          });

          window.setTimeout(() => {
            this.setState({
              successmessage: undefined,

            });

          }, 4000);

        })
        .catch((ex) => {
          //TODO: log client errors
          //TODO: SHOW ERROR

          this.setState({
            isLoading: false,
            searcherrormessage: submitSystemError,
            submitSearchStarted: false,
          });
        });

      this.setState({
        isLoading: true,
        successmessage: undefined,
      });
    } else {
      this.setState({
        isLoading: false,
        searcherrormessage: submitSystemError,
        submitSearchStarted: false,
        searchStarted: true,
      });
    }

  };

  public handleSubmit = () => {

    if (this.state.currentUser.email !== "") {
      // console.log(JSON.stringify(this.state.currentUser));

      fetch(apiurl + `/Music/SubmitVote?email=` + this.state.currentUser.email + `&name=` + this.state.currentUser.name + `&credential=` + this.state.currentUser.credential, {
        method: "POST",
        body: JSON.stringify({ song: this.state.currentSong, grade: this.state.selectedGrade }),
        credentials: "include",
        headers: {
          pragma: "no-cache",
          "cache-control": "no-cache",
          "content-type": "application/json",
        },
      })
        .then((response) => response.json() as Promise<StudentResponse>)
        .then((data) => {
          //TODO: SHOW SUCCESS
          if (data.errors) {
            this.setState({
              isLoading: false,
              errors: data.errors,
              selectionStarted: false,
              submitStarted: false,

            });
          }
          let message = (this.state.currentSong.selection === "VOTE") ? "You have successfully voted for " + this.state.currentSong.song :
            "You have removed your vote for " + this.state.currentSong.song
          this.setState({
            step: "SubmitSuccess",
            currentUser: data.student,
            availableEntries: data.availableEntries,
            isLoading: false,
            selectionStarted: false,
            submitStarted: false,
            searchStarted: false,
            writeInStarted: false,
            submitSearchStarted: false,
            successmessage: message,

          });

          window.setTimeout(() => {
            this.setState({
              successmessage: undefined,

            });

          }, 4000);

        })
        .catch((ex) => {
          //TODO: log client errors
          //TODO: SHOW ERROR

          this.setState({
            isLoading: false,
            submiterrormessage: submitSystemError,
            submitStarted: false,
          });
        });

      this.setState({
        isLoading: true,
        successmessage: undefined,
      });
    }

  };



  public StartSearch = () => {

    this.setState({
      searchStarted: true,
    });

  };

  public StartWriteIn = () => {

    this.setState({
      writeInStarted: true,
    });


  };

  public StartSelection = (song: any, artist: any, spotifyid: any, selection: string) => {
    this.setState({
      submiterrormessage: undefined,
      selectionStarted: true,
      submitStarted: false,
      currentSong: {
        song: song,
        artist: artist,
        selection: selection,
        thumbnail: "",
        preview_url: "",
        external_urls: { spotify: "", },
        spotifyid: spotifyid
      },
    });
  };



  public SubmitSearch = () => {
    this.setState({
      errormessage: this.state.errormessage,
      submitSearchStarted: true,
      searchStarted: true,
    });
    this.handleSearch();
  };

  public SubmitSelection = (currentSong: Song) => {
    this.setState({
      step: this.state.step,
      isLoading: this.state.isLoading,
      errormessage: this.state.errormessage,
      selectionStarted: true,
      submitStarted: true,
      currentSong: currentSong,
    });
    this.handleSubmit();
  };

  public componentDidMount() {
    this.ensureDataFetched();
  }

  private ensureDataFetched() {
    sanityClient
      .fetch(
        `*[_type == "eventsong" ] | order(title){
    title,
    thumbnail,
    artist
  
}`
      )
      .then((data: any) => {
        this.setState({
          postData: data,
        });
      })
      .catch(console.error);

  }

  public render() {
    let currentSongs = this.state.currentUser && this.state.currentUser.currentSongs;

    const strokeStyle = {
      "--tan": 0.41,
      fontSize: 16
    } as React.CSSProperties;
    const { step } = this.state;
    let parent = this;

    let rand = Math.floor(Math.random() * Math.floor(1000));
    return (
      <section id="MusicSurvey" className="anchor roomfornav">
        <div className={"alert-bar alert alert-success" + ((this.state.successmessage === undefined) ? " alert-bar-hidden" : "")} role="alert">
          <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <strong>Success!</strong> {this.state.successmessage}
        </div>
        <div className="sticky-top">
          <Card
            className="my-2"
            color="dark"
            outline
          >

            <CardBody className="p-1">

              <div className="musicselected-row">
                {currentSongs.map((item: Song, index) => {
                  if (item.song === undefined) {
                    return <div key={"nocurrentsongs" + index}></div>
                  }

                  let theimageurl = "url(" + item.thumbnail + ")";
                  let cleanSong = item.spotifyid ? item.song : "Requested Song";
                  return (

                    <div
                      key={"currentsongs" + index + rand}
                      className="musicselected-item-song"
                      style={{ backgroundImage: theimageurl, backgroundSize: "cover" }}
                    >

                      <div className="musicselected-row">
                        <div
                          className="musicselected-item-inner">
                          <Badge className="text-wrap">{cleanSong.length > 17 ? cleanSong.substring(0, 17) + "..." : cleanSong}</Badge>
                        </div>

                      </div>
                      <div className="musicselected-row">
                        <div
                          className="musicselected-item-inner">
                          {(parent.state.step === "Existing"
                            || parent.state.step === "New"
                            || parent.state.step === "SubmitSuccess") &&
                            <span className="pull-right" data-effect="fadeOut"><button type="button" onClick={() => { parent.StartSelection(item.song, item.artist, item.spotifyid, "No Selection") }}
                              className={"btn btn-success mt-3"}>
                              <i className={"fa-solid" + " fa-xmark fa-beat"} ></i></button></span>


                          }
                        </div>
                      </div>
                    </div>

                  );
                })}
              </div>

            </CardBody>
          </Card>
        </div>
        <div className="sticky-top" style={{ right: "5px", top: "96px", maxWidth: "100px" }}>
          {/* <div style={{ position: "absolute", right: "5px", top: "200px"}}> */}
          {this.state.currentUser.email &&
            <button type="button" onClick={() => { parent.StartSearch() }}
              className={"btn btn-info mt-3"}>
              <i className={"fa-solid" + " fa-magnifying-glass fa-beat"} ></i><div>Add</div></button>

          }
        </div>
        <div className="container bg-light">
          <div className="content">
            <div className="container d-flex justify-content-center">
              <div className="d-flex flex-column justify-content-between">

                {this.renderMusicSurvey(step, parent)}
                <GradeSelector setGrade={(newgrade: string) => {
                  this.setState({ selectedGrade: newgrade });
                }} selectedGrade={this.state.selectedGrade}></GradeSelector>
              </div>
            </div>
            {this.renderSongs(parent)}

            <PlayListEmbed selectedGrade={this.state.selectedGrade}></PlayListEmbed>
            {this.state.currentUser.email && this.state.selectedGrade && this.state.selectedGrade !== "" && <CardHeader className="pt-1 pb-1">

              {/* <button type="button" onClick={() => { parent.StartSearch() }}
                className={"btn btn-outline-success mt-3"}>
                <i className={"fa-solid" + " fa-magnifying-glass fa-beat"} ></i><div>Request New Song</div></button> */}


              <button type="button" onClick={() => { parent.StartWriteIn() }}
                className={"btn btn-outline-success mt-3 ml-5"}>
                <i className={"fa-solid" + " fa-envelope fa-beat"} ></i><div>Write-In REQUEST</div></button>
            </CardHeader>}
            {this.state.currentUser.email && this.state.selectedGrade && this.state.selectedGrade !== "" && <GradeSelector setGrade={(newgrade: string) => {
              this.setState({ selectedGrade: newgrade });
            }} selectedGrade={this.state.selectedGrade}></GradeSelector>
            }

          </div>
        </div>

        <Modal isOpen={this.state.isLoading}>
          <ModalHeader>
            Please Wait...
          </ModalHeader>
          <ModalBody>
            <div className="spinner-grow text-warning" role="status">
            </div>
            this might take a few moments
          </ModalBody>
        </Modal>
      </section>

    );


  }


  public renderMusicSurvey(step: string, parent: any) {

    let message = "Sign In with your school account and vote for your top dance song.";

    switch (step) {
      case "ErrorLogin":
      case "Login":

        break;
      case "New":
      case "Existing":
      case "SubmitSuccess":
        message = "Make your song requests by voting for your top dance songs!  Help shape the playlist for this years event!"
        break;

        break;
      // never forget the default case, otherwise VS code would be mad!
      default:
      // do nothing
    }

    let IsLoggedIn = this.state.availableEntries.length > 0;

    return (
      <>
        {!IsLoggedIn
          && <img
            alt="knight on horse"
            src="https://i.scdn.co/image/ab67616d0000b273a48964b5d9a3d6968ae3e0de"

            className="img-fluid flyerimg"
          />
        }
        <GoogleSignIn
          Message={message}
          Errors={[parent.state.errormessage]}
          isLoggedIn={this.state.isLoggedIn}
          onSuccess={(res: any) => {
            // console.log(res);
            this.setState({
              step: "Checking",
              isLoading: false,
              currentUser: emptyUser,
              errormessage: "",
            });
            this.Authorize(res);
          }}
          onFailure={() => {
            console.log("Failure");
            this.setState({
              step: "ErrorLogin",
              isLoading: false,
              currentUser: emptyUser,
              errormessage: systemError,
            });

          }}
          parentLogout={() => {
            this.setState({
              isOpen: false,
              step: "Login",
              isLoading: false,
              errormessage: undefined,
              submiterrormessage: undefined,
              successmessage: undefined,
              selectionStarted: false,
              submitStarted: false,
              currentUser: emptyUser,
              currentSong: emptySong,
              errors: [],
              isLoggedIn: false,
            });
          }}
          UserImage={this.state.currentUser.thumbnail}

        />
        <em>Lehman-Jackson 5th and 6th grade fall dance!  Voting will conclude 11/15/2023</em>
        <em>if you have any issues or questions, please contact us at:
          <a className="mx-3" href="mailto:lehmanjacksonptoboard@gmail.com">lehmanjacksonptoboard@gmail.com</a>
        </em>
      </>
    );


  }

  public renderSongs(parent: any) {

    //let posts: EventSong[] = parent.state.postData;

    let posts: Entry[] = parent.state.availableEntries;

    return (
      <div>

        <section className="showcase-container">
          {posts.map((item, index) => {

            let itemInterest = "No Selection";
            let currentSongs = parent.state.currentUser && parent.state.currentUser.currentSongs;
            let thisItemInterest = currentSongs && (currentSongs.length > 0) ? currentSongs.find((e: Song) => e.song === item.song.song) : "No Selection";
            if (thisItemInterest) {
              itemInterest = "VOTED";
            }

            if (item.grade === this.state.selectedGrade) {

              let voteText = item.grade + " Grade VOTES=" + item.votes;
              switch (item.grade) {
                case "K":
                  voteText = "Kindergarten VOTES=" + item.votes;
                  break;
                case "1":
                  voteText = item.grade + "st grade VOTES=" + item.votes;
                  break;
                case "2":
                  voteText = item.grade + "nd grade VOTES=" + item.votes;
                  break;
                case "3":
                  voteText = item.grade + "rd grade VOTES=" + item.votes;
                  break;
                case "4":
                  voteText = item.grade + "th grade VOTES=" + item.votes;
                  break;
                case "5":
                  voteText = item.grade + "th grade VOTES=" + item.votes;
                  break;
                case "6":
                  voteText = item.grade + "th grade VOTES=" + item.votes;
                  break;
              }


              return (

                <div key={"availableEntries" + index} className="showcase-item">
                  <Card
                    style={{
                      width: '18rem'
                    }}
                  >
                    <CardBody className="p-1">
                      <CardTitle tag="h5">
                        {item.song.song}
                      </CardTitle>
                      <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                      >
                        <Badge color="primary">
                          {voteText}
                        </Badge>
                      </CardSubtitle>
                    </CardBody>
                    <Row>
                      <Col className="pr-1">{item.song.thumbnail && <img
                        src={item.song.thumbnail}
                        alt="Image"
                        className="img-fluid"
                        style={{ maxWidth: "150px" }}
                      />}
                      </Col>
                      <Col>{(parent.state.step === "Existing"
                        || parent.state.step === "New"
                        || parent.state.step === "SubmitSuccess") && <div className="item2">

                          <CommitteeButton
                            OnClick={() => { if (itemInterest !== "VOTED") parent.StartSelection(item.song.song, item.song.artist, item.song.spotifyid, "VOTE") }}
                            Title={item.song.song}
                            ItemInterest={itemInterest}
                            Role="VOTED"
                            Text={(itemInterest !== "VOTED") ? "VOTE" : undefined}
                          ></CommitteeButton>

                          {(itemInterest === "VOTED") && <button type="button" onClick={() => {
                            parent.StartSelection(item.song.song, item.song.artist, item.song.spotifyid, "No Selection")
                          }}
                            className={"btn btn-outline-success mt-3"}>
                            <i className={"fa-solid" + " fa-xmark fa-beat"} ></i><div>Remove Vote</div></button>
                          }
                          {
                            item.song.spotifyid && item.song.preview_url && <audio controls
                              style={{ maxWidth: "105px" }}
                            >
                              <source src={item.song.preview_url} type="audio/mpeg" />
                            </audio>

                          }
                          {
                            item.song.spotifyid && item.song.preview_url === undefined && item.song.external_urls && item.song.external_urls.spotify && <a target="_blank" href={item.song.external_urls.spotify}>{ExternalLinkAlt}</a>

                          }
                        </div>}</Col>
                    </Row>

                  </Card>

                </div>
              );
            } else {
              return (<div key={"nocurrentsongs2" + index}></div>)
            }
          })}


          <Modal isOpen={parent.state.writeInStarted} className="modal-bar" toggle={() => { this.setState({ writeInStarted: false }); }}>
            <ModalHeader toggle={() => { this.setState({ writeInStarted: false }); }}>
              Request Song
            </ModalHeader>
            <ModalBody className="px-0">
              <div>
                {this.state.submiterrormessage &&
                  this.state.submiterrormessage != undefined &&
                  this.state.submiterrormessage != "" &&
                  <div className="alert alert-danger" role="alert">{this.state.submiterrormessage}</div>}
              </div>
              <Form>
                <FormGroup>
                  <Label for="writeintext">Enter name of song you would like to request.</Label>

                  <Input type="text" name="writeintext" id="writeintext" placeholder=""
                    onKeyPress={(event: any) => {
                      if (event.key === "Enter") {
                        // code for enter
                        (
                          document.getElementById("writeinbtn") as HTMLButtonElement
                        ).click();

                        event.preventDefault();

                      }
                    }}
                    onChange={(event) => {
                      let currentState = this.state;
                      currentState.writeinTerm = event.target.value;
                      this.setState(currentState);
                    }
                    }
                  />
                  {(parent.state.step === "Existing"
                    || parent.state.step === "New"
                    || parent.state.step === "SubmitSuccess") &&
                    <span className="pull-right" data-effect="fadeOut"><button type="button" onClick={() => { parent.StartSelection(this.state.writeinTerm, "", "", "VOTE") }}
                      className={"btn btn-success mt-3"}>
                      Submit</button></span>


                  }
                </FormGroup>
              </Form>
            </ModalBody>
          </Modal>

          <Modal isOpen={parent.state.searchStarted} className="modal-bar" toggle={() => { this.setState({ searchStarted: false }); }}>
            <ModalHeader toggle={() => { this.setState({ searchStarted: false }); }}>
              <Form>
                <FormGroup>
                  <Input type="text" name="searchtext" id="searchtext" placeholder=""
                    onKeyPress={(event: any) => {
                      if (event.key === "Enter") {
                        // code for enter
                        (
                          document.getElementById("searchbtn") as HTMLButtonElement
                        ).click();

                        event.preventDefault();

                      }
                    }}
                    onChange={(event) => {
                      let currentState = this.state;
                      currentState.searchTerm = event.target.value;
                      this.setState(currentState);
                    }
                    }
                  />
                  <button
                    id="searchbtn"
                    type="button"
                    disabled={parent.state.submitSearchStarted}
                    onClick={() => {
                      parent.SubmitSearch()
                    }}
                    className="btn btn-outline-success active mt-3">

                    <div className="d-flex justify-content-between"><i className="fa fa-check medium m-1"></i><div>Search by song or spotify ID</div></div></button>
                </FormGroup>
              </Form>
            </ModalHeader>
            <ModalBody className="px-0">
              <div>
                {this.state.submiterrormessage &&
                  this.state.submiterrormessage != undefined &&
                  this.state.submiterrormessage != "" &&
                  <div className="alert alert-danger" role="alert">{this.state.submiterrormessage}</div>}
              </div>
              <div className="d-flex justify-content-between">
                <SearchResults searchSongs={this.state.searchSongs}
                  submitSpotifyID={this.StartSelection} />
              </div>
            </ModalBody>
          </Modal>

          <Modal isOpen={parent.state.selectionStarted} className="modal-bar">
            <ModalHeader>
              {parent.state.currentSong.selection === "No Selection" && "REMOVE VOTE"}
              {parent.state.currentSong.selection !== "No Selection" && "SUBMIT VOTE"}
            </ModalHeader>
            <ModalBody>
              {parent.state.currentUser && parent.state.currentUser.currentSongs && parent.state.currentUser.currentSongs[0] && parent.state.currentUser.currentSongs[0].song
                && parent.state.currentSong.selection !== "No Selection" &&
                <Card
                  className="my-2"
                  color="dark"
                  outline
                >

                  <CardBody className="p-1">

                    <div className="musicselected-row">
                      <Alert>You will need to remove a song before voting for another one</Alert>

                      {parent.state.currentUser.currentSongs.map((item: Song, currentsongsindex: any) => {
                        if (item.song === undefined) {
                          return <></>
                        }

                        let rand2 = Math.floor(Math.random() * Math.floor(1000));
                        let theimageurl = "url(" + item.thumbnail + ")";

                        let cleanSong = item.spotifyid ? item.song : "Requested Song";
                        return (

                          <div
                            key={"currentsongs" + currentsongsindex + rand2}
                            className="musicselected-item-song"
                            style={{ backgroundImage: theimageurl, backgroundSize: "cover" }}
                          >

                            <div className="musicselected-row">
                              <div
                                className="musicselected-item-inner">
                                <Badge className="text-wrap">{cleanSong.length > 17 ? cleanSong.substring(0, 17) + "..." : cleanSong}</Badge>
                              </div>

                            </div>
                            <div className="musicselected-row">
                              <div
                                className="musicselected-item-inner">
                                {(parent.state.step === "Existing"
                                  || parent.state.step === "New"
                                  || parent.state.step === "SubmitSuccess") &&
                                  <span className="pull-right" data-effect="fadeOut"><button type="button" onClick={() => { parent.StartSelection(item.song, item.artist, item.spotifyid, "No Selection") }}
                                    className={"btn btn-success mt-3"}>
                                    <i className={"fa-solid" + " fa-xmark fa-beat"} ></i></button></span>


                                }
                              </div>
                            </div>
                          </div>

                        );
                      })}
                    </div>

                  </CardBody>
                </Card>
              }<>
                {parent.state.currentUser && parent.state.currentUser.currentSongs
                  && (parent.state.currentUser.currentSongs[0] === undefined || parent.state.currentUser.currentSongs[0].spotifyid === undefined || parent.state.currentSong.selection === "No Selection")
                  &&
                  <div>
                    {this.state.submiterrormessage &&
                      this.state.submiterrormessage != undefined &&
                      this.state.submiterrormessage != ""
                      && <div className="alert alert-danger" role="alert">{this.state.submiterrormessage}</div>}
                    {parent.state.currentSong.selection === "No Selection" && <p>Would you like to remove your vote for <strong>{parent.state.currentSong.song}</strong>?</p>}
                    {parent.state.currentSong.selection !== "No Selection" && <p>Would you like to submit your Vote for <strong>{parent.state.currentSong.selection}</strong> for <strong>{parent.state.currentSong.song}</strong>?</p>}
                    <mark>Select your grade.  *grade will be validated</mark>
                    <GradeSelector setGrade={(newgrade: string) => {
                      this.setState({ selectedGrade: newgrade });
                    }} selectedGrade={this.state.selectedGrade}></GradeSelector>


                  </div>}
                <div className="d-flex justify-content-between">
                  {parent.state.currentUser && parent.state.currentUser.currentSongs
                    && (parent.state.currentUser.currentSongs[0] === undefined || parent.state.currentUser.currentSongs[0].spotifyid === undefined || parent.state.currentSong.selection === "No Selection")
                    && <button type="button"
                      disabled={parent.state.submitStarted} onClick={() => { parent.SubmitSelection(parent.state.currentSong) }}
                      className="btn btn-outline-success active mt-3">

                      <div className="d-flex justify-content-between"><i className="fa fa-check medium m-1"></i><div>SUBMIT</div></div></button>
                  }
                  {parent.state.submitStarted && <div><div className="spinner-grow text-success" role="status">

                  </div><span className="visually-hidden">Processing...</span></div>}
                  <button type="button"
                    disabled={parent.state.submitStarted} onClick={() => { parent.CancelSelection() }}
                    className="btn btn-outline-danger active mt-3">

                    <div className="d-flex justify-content-between"><i className="fa fa-xmark medium m-1"></i><div>Cancel</div></div></button>

                </div></>

            </ModalBody>
          </Modal>
        </section>
      </div>
    );
  }
}
